import * as React from "react";

export class HTMLContent extends React.Component<{ content?; className? }> {
  render() {
    return <div className={this.props.className} dangerouslySetInnerHTML={{ __html: this.props.content }} />;
  }
}

export class Content extends React.Component<{ content?; className? }> {
  render() {
    return <div className={this.props.className}>{this.props.content}</div>;
  }
}
